import styled from 'styled-components'
import { Flex, Button } from '@renderbus/common/components'
import { Media, typography, spacing } from '@renderbus/common/theme'

export const BannerContainer = styled(Flex)`
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 900px;
  ${Media.lessThan(Media.small)} {
    height: 500px;
  }
`
export const YearendRechargeContent = styled(Flex)`
  flex-direction: column;
  padding-top: 96px;
  p {
    color: #fff;
  }
  ${Media.lessThan(Media.small)} {
    padding-top: 70px;
  }
`
export const ExclusiveUser = styled.div`
  width: 300px;
  height: 44px;
  border: 2px solid #fad7a7;
  border-radius: 20px;
  text-align: center;
  font-size: ${typography.h3};
  font-weight: 500;
  color: #fad7a8;
  background: linear-gradient(0deg, #fad8a9 0%, #efcbad 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: ${spacing.base};
  ${Media.lessThan(Media.small)} {
    width: 205px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #fad7a7;
    font-size: ${typography.h4};
  }
`
export const YearendRechargeText = styled.img`
  width: 516px;
  height: 65px;
  ${Media.lessThan(Media.small)} {
    width: 330px;
    height: 42px;
  }
`
export const ToDetailBtn = styled(Button)`
  width: 140px;
  height: 40px;
  line-height: 40px;
  background: linear-gradient(0deg, #f4bd7e, #fdf5bf);
  border-radius: 20px;
  color: #2d2c28;
  font-weight: bold;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    width: 120px;
    height: 35px;
    line-height: 35px;
  }
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 0 170px;
  ${Media.lessThan(Media.small)} {
    padding: 0;
  }
`
export const ParticipationWayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 0 170px;
`
export const RulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 0 170px;
`
export const TitleImg = styled.img`
  padding: 70px 0;
  ${Media.lessThan(Media.small)} {
    width: 235px;
    padding: 30px 0;
  }
`
export const RulesTitleImg = styled(TitleImg)`
  padding-bottom: 25px;
`
export const StepImgWrap = styled.div`
  width: 100%;
  img:nth-child(2) {
    display: none;
  }
  ${Media.lessThan(Media.small)} {
    padding: 0 20px;
    img:first-child {
      display: none;
    }
    img:nth-child(2) {
      width: 100%;
      display: block;
    }
  }
`
export const PrizeList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const PrizeItem = styled.div`
  width: 340px;
  height: 296px;
  margin-bottom: 30px;
  text-align: center;
  color: #ffffff;
  p {
    margin-top: ${spacing.base};
  }
  ${Media.lessThan(Media.small)} {
    width: 170px;
    height: 153px;
    margin-bottom: 22px;
    font-size: ${typography.textThin};
    p {
      margin-top: 16px;
    }
  }
`
export const PrizeImgWrap = styled.div`
  width: 100%;
  height: 250px;
  border-radius: 30px;
  background: #444444;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  ${Media.lessThan(Media.small)} {
    height: 125px;
    img {
      height: 60%;
    }
  }
`
export const RulesList = styled.div`
  color: #ccc;
  width: 100%;
  p {
    height: 30px;
    font-size: ${typography.textSmall};
    font-weight: 400;
    color: #cccccc;
    margin: 20px 0;
    button {
      width: 100px;
      height: 30px;
      background: linear-gradient(0deg, #fdfdc8 0%, #fad7a7 0%, #faf5d1 100%);
      font-size: ${typography.textSmall};
      font-weight: 500;
      color: #222222;
      padding: 0;
      line-height: 30px;
    }
    a {
      cursor: pointer;
      color: #cccccc;
    }
  }
  span {
    display: inline-block;
    width: 30px;
    height: 30px;
    background: linear-gradient(30deg, #fdfdc8 0%, #fad7a7 0%, rgba(250, 245, 209, 0) 100%);
    border-radius: 50%;
    margin-right: 10px;
    text-align: center;
    line-height: 30px;
  }
  ${Media.lessThan(Media.small)} {
    p {
      height: auto;
      line-height: 1.5;
      font-size: ${typography.textThin};
      margin: 0;
      button {
        display: block;
        margin: 22px auto 5px;
      }
    }
    span {
      display: none;
    }
  }
`
export const DescriptionList = styled.div`
  width: 100%;
  font-size: ${typography.textSmall};
  font-weight: 400;
  color: #cccccc;
  a {
    color: #ffd69d;
    cursor: pointer;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.textThin};
  }
`
export const QrcodeList = styled.span`
  display: flex;
  margin-top: 25px;
`
export const QrcodeWrap = styled.span`
  width: 190px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 120px;
    height: 120px;
  }
  span {
    margin-top: 14px;
    text-align: center;
  }
  ${Media.lessThan(Media.small)} {
    img {
      width: 100px;
      height: 100px;
    }
  }
`
