import React from 'react'
import { graphql } from 'gatsby'

import { Wrapper, SEO, Billboard, Button } from '@renderbus/common/components'
import {
  BannerContainer,
  YearendRechargeContent,
  ExclusiveUser,
  ToDetailBtn,
  YearendRechargeText,
  ContentContainer,
  TitleImg,
  RulesTitleImg,
  PrizeList,
  PrizeItem,
  PrizeImgWrap,
  RulesList,
  DescriptionList,
  QrcodeList,
  QrcodeWrap,
  StepImgWrap,
} from './1212.atom'
import { API, Axios } from '@renderbus/common/utils'

import Beoplay from '../images/year-end-recharge/beoplay.png'
import HardDisk from '../images/year-end-recharge/hard-disk.png'
import IPhone12 from '../images/year-end-recharge/iphone12.png'
import JDCard from '../images/year-end-recharge/jd-card.png'
import Line from '../images/year-end-recharge/line.png'
import MousePad from '../images/year-end-recharge/mouse-pad.png'
import MovieTicket from '../images/year-end-recharge/movie-ticket.png'
import RTX4000 from '../images/year-end-recharge/rtx4000.png'
import Umbrella from '../images/year-end-recharge/umbrella.png'
import Watch from '../images/year-end-recharge/watch.png'
import YearendRechargeTextImg from '../images/year-end-recharge/year-end-recharge-text.png'
import PrizeDisplayTitle from '../images/year-end-recharge/prize-display-title.png'
import ParticipationWayTitle from '../images/year-end-recharge/participation-way-title.png'
import RulesTitle from '../images/year-end-recharge/rules-title.png'
import QrcodeBilibili from '../images/year-end-recharge/qrcode-bilibili.png'
import QrcodeWechat from '../images/year-end-recharge/qrcode-wechat.png'
import stepPC from '../images/year-end-recharge/step-pc.png'
import stepMobile from '../images/year-end-recharge/step-mobile.png'
import Layout from '../molecules/layout'

const prizeImgList = [
  {
    name: '英伟达RTX4000显卡 X1',
    src: RTX4000,
  },
  {
    name: 'iPhone 12（64GB）X1',
    src: IPhone12,
  },
  {
    name: 'Apple Watch Series 6 X1',
    src: Watch,
  },
  {
    name: 'B&O beoplay H4 无线耳机 X1',
    src: Beoplay,
  },
  {
    name: '西部数据5TB 移动硬盘 X1',
    src: HardDisk,
  },
  {
    name: '100元京东卡 X5',
    src: JDCard,
  },
  {
    name: 'Renderbus订制雨伞 X10',
    src: Umbrella,
  },
  {
    name: '通用电影券 X15',
    src: MovieTicket,
  },
  {
    name: 'Renderbus定制鼠标垫 X20',
    src: MousePad,
  },
  {
    name: 'Renderbus定制数据线 x50',
    src: Line,
  },
]
class YearendRecharge extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleSumbit = this.handleSumbit.bind(this)
  }

  instance = new Axios(API.auth.host)

  async handleSumbit() {
    const qualificationRes = await this.instance.post(API.event.getActivityQualification, {
      activityId: 6,
    })
    if (
      (qualificationRes && [602, 603].includes(qualificationRes.code)) ||
      qualificationRes.data.fillCount === 0
    ) {
      window.location.href = 'https://task.renderbus.com/center/user/topUp'
    } else {
      window.location.href = 'https://www.renderbus.com/nvidia-form.html?activityId=6'
    }
  }

  render() {
    const {
      location,
      data,
      pageContext: { topThreeShare },
    } = this.props
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='双十二福利来袭,年终充值送豪礼 - Renderbus云渲染农场'
          keywords='影视渲染,动画渲染,渲染农场,云渲染农场'
          description='Renderbus云渲染农场双十二送豪礼，充值参与抽奖，有机会赢得iPhone 12、英伟达RTX4000显卡、Apple Watch……,活动时间2020年12月09日-12月29日.'
          sharePostSlug='1212.html'
        />
        <BannerContainer>
          <YearendRechargeContent>
            <ExclusiveUser>影视动画渲染用户专享</ExclusiveUser>
            <YearendRechargeText src={YearendRechargeTextImg} alt='年终充值送豪礼' />
            <p>2020年12月9日-29日充值抽奖</p>
            <ToDetailBtn as='a' href='https://task.renderbus.com/center/user/topUp'>
              立即充值
            </ToDetailBtn>
          </YearendRechargeContent>
          <Billboard
            fluid={[
              data.bannerYearendRechargeMobile.childImageSharp.fluid,
              {
                ...data.bannerYearendRecharge.childImageSharp.fluid,
                media: `(min-width: 780px)`,
              },
            ]}
          />
        </BannerContainer>
        <Wrapper>
          <ContentContainer>
            <TitleImg src={PrizeDisplayTitle} alt='奖品展示' />
            <PrizeList>
              {prizeImgList.map((item, index) => {
                return (
                  <PrizeItem key={index}>
                    <PrizeImgWrap>
                      <img src={item.src} alt={item.name} />
                    </PrizeImgWrap>
                    <p>{item.name}</p>
                  </PrizeItem>
                )
              })}
            </PrizeList>
          </ContentContainer>
          <ContentContainer>
            <TitleImg src={ParticipationWayTitle} alt='参与方式' />
            <StepImgWrap>
              <img src={stepPC} alt='' />
              <img src={stepMobile} alt='' />
            </StepImgWrap>
          </ContentContainer>
          <ContentContainer>
            <RulesTitleImg src={RulesTitle} alt='活动规则' />
            <RulesList>
              <p>
                <span>1</span>
                影视动画用户单笔充值每满5000元，即可获得一张抽奖券，抽奖券可点击后台活动浮标查看
              </p>
              <p>
                <span>2</span>
                12月30日统一在
                <a
                  href='https://space.bilibili.com/408650439/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  B站（@Renderbus瑞云渲染）
                </a>{' '}
                直播抽奖，中奖后微信添加瑞云小助手（rayvision1），凭抽奖券码领奖，领奖兑换时间截止到2021年1月7日24点；
              </p>
              <p>
                <span>3</span>每个用户通过充值最多可获得5张抽奖券。
              </p>
              <p>
                <span>4</span>充值后，填写问卷可多获得1张抽奖券！
                <Button onClick={this.handleSumbit}>填写问卷</Button>
              </p>
            </RulesList>
            <DescriptionList>
              <p>活动说明：</p>
              <p>1、本次活动仅限影视动画渲染用户参与（青云平台用户不参与）</p>
              <p>2、抽奖奖品一律不支持折换现金</p>
              <p>3、未在截止日期前兑换奖品的用户视为放弃奖品</p>
              <p>
                4、抽奖直播观看地址：
                <a
                  href='http://live.bilibili.com/21902293'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  http://live.bilibili.com/21902293
                </a>
              </p>
              <p>
                5、抽奖直播的具体时间将于瑞云的服务号、B站等平台公布，欢迎关注
                <QrcodeList>
                  <QrcodeWrap>
                    <img src={QrcodeWechat} alt='瑞云渲染服务号' />
                    <span>瑞云渲染服务号</span>
                  </QrcodeWrap>
                  <QrcodeWrap>
                    <img src={QrcodeBilibili} alt=' B站账号：Renderbus瑞云渲染' />
                    <span>B站账号：Renderbus瑞云渲染</span>
                  </QrcodeWrap>
                </QrcodeList>
              </p>
              <p>6、本活动最终解释权归瑞云科技所有</p>
            </DescriptionList>
          </ContentContainer>
        </Wrapper>
      </Layout>
    )
  }
}

export default YearendRecharge

export const query = graphql`
  query {
    bannerYearendRecharge: file(
      relativePath: { regex: "/year-end-recharge/year-end-recharge-banner.png/" }
    ) {
      ...fluidImage
    }
    bannerYearendRechargeMobile: file(
      relativePath: { regex: "/year-end-recharge/year-end-recharge-banner-mobile.png/" }
    ) {
      ...fluidImage
    }
  }
`
